import React from 'react'
import loading from "./LoadingAnimationModule.module.css"
const LoadingAnimation = (props) => {
    const {pageHeader} = props
    return (
        <div className={loading.wrapper}>
            <div className={loading.circle}></div>
            <div className={loading.circle}></div>
            <div className={loading.circle}></div>
            <div className={loading.shadow}></div>
            <div className={loading.shadow}></div>
            <div className={loading.shadow}></div>
            <div className={loading.spanBlock}>
            <span>{pageHeader}</span>
            </div>
        </div>
        // <div classNameName={loading.load}>
        //     <div>G</div>
        //     <div>N</div>
        //     <div>I</div>
        //     <div>D</div>
        //     <div>A</div>
        //     <div>O</div>
        //     <div>L</div>
        // </div>

    )
}

export default LoadingAnimation