import React, { useState, useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Auth from './../IsAuthenticate/Auth';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

const PrivateIndexRoute = ({ children }) => {
  const navigate = useNavigate()
  const isAuth = Auth()
  isAuth.then(function (res) {
    if (!res) {
      return navigate('/', { replace: true })
    }
  })
  return children
}
// async function Auth() {
//    console.log("Auth token " + localStorage.getItem('token'))
//    const result = await axios.get("http://localhost:5000/isAuthenticate", {
//       headers: {
//          Authorization: `Bearer ${localStorage.getItem('token')}`
//       }
//    })
//    console.log(result.data.message)
//    return result.data.message ? true: false
// }

export default PrivateIndexRoute