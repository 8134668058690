import React, { lazy, Suspense, useState, useEffect, createContext } from 'react'
import "bootstrap/dist/css/bootstrap.min.css"
import './App.css'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import { BrowserRouter, Route, useLocation, Routes } from 'react-router-dom';
import LoadingAnimation from './Components/LoadingComponent/LoadingAnimation';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
// import RetrieveEmpID from './Actions/RetrieveEmpID';
// import MaintenancePage from './Components/Maintenance/MaintenancePage';
// import { posthog } from 'posthog-js';
// import { useSearchParams } from 'react-router-dom';
import PrivateIndexRoute from './Components/ProtectedRoute/PrivateIndexRoute';
// import { useSelector, useDispatch } from 'react-redux';
// import { bindActionCreators } from 'redux';
// import * as allActions from './Redux/Action'
// import "react-toastify/dist";


const Login = (
  lazy(async () => (
    await import('./Components/Login')
  ))
)
const NavbarHomeScreen = (
  lazy(async () => (
    await import('./Components/NavbarMain/NavbarHomeScreen')
  ))
)

const HrmsFooter = (
  lazy(async () => (
    await import('./Components/Footer/HrmsFooter')
  ))
)
const IndexPage = (
  lazy(async () => (
    await import('./Components/HomePage/IndexPage')
  ))
)
// const ProfilePage = (
//   lazy(async () => (
//     await import('./Components/AfterLogin/ProfilePage')
//   ))
// )
const ApplyLeave = (
  lazy(async () => (
    await import('./Components/AfterLogin/ApplylLeave')
  ))
)
const ApplyTimeSheet = (
  lazy(async () => (
    await import('./Components/AfterLogin/ApplyTimeSheet')
  ))
)
const RegistrationSectionOne = (
  lazy(async () => (
    await import('./Components/RegistrationPage/RegistrationSectionOne')
  ))
)
const AddCustomers = (
  lazy(async () => (
    await import('./Components/AfterLogin/AddCustomer')
  ))
)
const AddProject = (
  lazy(async () => (
    await import('./Components/AfterLogin/AddProject')
  ))
)
const AddTask = (
  lazy(async () => (
    await import('./Components/AfterLogin/AddTask')
  ))
)
const AddReporting = (
  lazy(async () => (
    await import('./Components/AfterLogin/AddReporting')
  ))
)
const ChangePassword = (
  lazy(async () => (
    await import('./Components/AfterLogin/ChangePassword')
  ))
)
const EmployeeRelievingStatus = (
  lazy(async () => (
    await import('./Components/AfterLogin/EmployeeRelievingStatus')
  ))
)
const HRPolicy = (
  lazy(async () => (
    await import('./Components/AfterLogin/HRPolicy')
  ))
)
const LeaveHR = (
  lazy(async () => (
    await import('./Components/AfterLogin/LeavePolicy')
  ))
)
const NotFound = (
  lazy(async () => (
    await import('./Components/NotFound/NotFoundTemplate')
  ))
)
const HrmsEditEmployee = (
  lazy(async () => (
    await import('./Components/AfterLogin/HrmsEditEmployee')
  ))
)
const EmployeeDatas = (
  lazy(async () => (
    await import('./Components/AfterLogin/EmployeeTable')
  ))
)
const CustomerDatas = (
  lazy(() => (
    import('./Components/AfterLogin/CustomerTable')
  ))
)
const ProjectDatas = (
  lazy(() => (
    import('./Components/AfterLogin/ProjectTable')
  ))
)
const TaskDatas = (
  lazy(() => (
    import('./Components/AfterLogin/TaskTable')
  ))
)
const ReportDatas = (
  lazy(() => (
    import('./Components/AfterLogin/ReportTable')
  ))
)
const OverAllEmployeeTable = (
  lazy(() => (
    import('./Components/AfterLogin/OverAllTables/OverAllEmployeeTable')
  ))
)
const LeaveTable = (
  lazy(() => (
    import('./Components/AfterLogin/LeaveTable')
  ))
)
const OverAllLeaveTable = (
  lazy(() => (
    import('./Components/AfterLogin/OverAllTables/OverAllLeaveTable')
  ))
)
const LeaveApprovalTable = (
  lazy(() => (
    import('./Components/AfterLogin/Approvals/LeaveApproval')
  ))
)

const ForgotPassword = (
  lazy(() => (
    import('./Components/Forgotpassword/ForgotPassword')
  ))
)
const TimeSheetTable = (
  lazy(() => (
    import('./Components/AfterLogin/TimeSheetTable')
  ))
)
const TimeSheetApproval = (
  lazy(() => (
    import('./Components/AfterLogin/TimeSheetApproval')
  ))

)
const OverAllTimeSheet = (
  lazy(() => (
    import('./Components/AfterLogin/OverAllTimeSheet')
  ))

)
const HomePage = (
  lazy(() => (
    import('./Components/AfterLogin/HomePage')
  ))
)

const MyProfilePage = (
  lazy(() => (
    import('./Components/AfterLogin/MyProfilePage')
  ))
)
const Leavebalance = (
  lazy(() => (
    import('./Components/AfterLogin/OverAllTables/OverAllLeaveBalance')
  ))
)
const ReportGeneration = (
  lazy(() => (
    import('./Components/AfterLogin/ReportGenerate/Reportgeneration')
  ))

)
const HolidayList = (
  lazy(() => (
    import('./Components/AfterLogin/Holidays/HolidayList')
  ))

)
const UploadHolidayList = (
  lazy(() => (
    import('./Components/AfterLogin/Holidays/UploadHoliday')
  ))

)

// this function is updated on 13/06/2024.

// const CookieBanner = (
//   lazy(() => (
//     import('./Components/AfterLogin/CookieBannerTemplate/CookieBanner')
//   ))
// )


const TabMenu = (
  lazy(() => (
    import('./Components/AfterLogin/Dashboard/TabMenu')
  ))

)
const ProfilePage = (
  lazy(() => (
    import('./Components/AfterLogin/ProfilePage')
  ))

)
const PaySlipUploading = (
  lazy(() => (
    import('./Components/AfterLogin/UploadPaySlip/PaySlipUploading')
  ))
)
const UploadAppraisal = (
  lazy(() => (
    import('./Components/AfterLogin/UploadPaySlip/UploadAppraisal')
  ))
)
const Jobuploads = (
  lazy(() => (
    import('./Components/AfterLogin/JobRecruitment/Jobuploads')
  ))
)
const Viewjobs = (
  lazy(() => (
    import('./Components/AfterLogin/JobRecruitment/Viewjobs')
  ))

)
const Jobdisplaypage = (
  lazy(() => (
    import('./Components/AfterLogin/Career/Jobdisplaypage')
  ))


)
const JobApplicationTable = (
  lazy(() => (
    import('./Components/AfterLogin/Applications/JobApplicationTable')
  ))
)
const AttendanceTable = (
  lazy(() => (
    import('./Components/AfterLogin/Attendance/AttendanceTable')
  ))
)
const CRM_Main_Page = (
  lazy(() => (
    import('./Components/AfterLogin/Tickets/CRM_Main_Page')
  ))

)
const Allocateresources = (
  lazy(() => (
    import('./Components/AfterLogin/Allocation/Allocateresources')
  ))


)
const AllocationTable = (
  lazy(() => (
    import('./Components/AfterLogin/Allocation/AllocationTable')
  ))
)
const TicketsPage = (
  lazy(() => (
    import('./Components/AfterLogin/Tickets/TicketsPage')
  ))
)
const TicketDisplay = (
  lazy(() => (
    import('./Components/AfterLogin/Tickets/TicketDisplay')
  ))

)
const Form16 = (
  lazy(() => (
    import('./Components/AfterLogin/UploadPaySlip/Form16Files')
  ))
)
const AllTickets = (
  lazy(() => (
    import('./Components/AfterLogin/Tickets/AllTickets')
  ))
)
const ReportingManager = (
  lazy(() => (
    import('./Components/AfterLogin/Replace/ReportingManager')
  ))
)

export const UserContextMain = createContext()
function App() {
  const navigate = useNavigate()

  // this line is updated on 13/06/2024.
  // const { ticketID } = useParams()

  // const empData = useSelector((state) => state.getEmpData)
  // const dispatch = useDispatch()
  // const { getEmployeeIDandName } = bindActionCreators(allActions, dispatch)
  const locationName = useLocation();

  // this line is updated on 13/06/2024.
  // const { jobNo } = useParams()

  const [cookie, setCookie] = useState(false)
  // const [isAbudhabi, setIsAbuDhabi] = useState()
  const pathname = locationName.pathname.split('/')
  // console.log(pathname)
  useEffect(async () => {
    setTimeout(() => {
      setCookie(true)
    }, 1500);
    // console.log('mob', navigator.userAgent.match(/Android/i)[0])
    await axios.get(`${process.env.REACT_APP_DOMAINNAME}/navbar/checkforNavbar`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then((res) => {
      // console.log(locationName.pathname)
      if (res.data.message) {
        // alert('hello')
        // const data = getEmployeeIDandName()
        // console.log('empData is ', data)
        if (locationName.pathname === '/' || locationName.pathname === '/registerAndUpdate') {
          return navigate('/userprofile', { replace: true })
        }
      }

    })

  }, [])

  return (
    <Suspense fallback={<LoadingAnimation pageHeader={'Loading'} />}>
      {/* <MaintenancePage /> */}
      {locationName.pathname === '/' || locationName.pathname === '/forgotPassword' ||
        
        locationName.pathname.includes('/ticket-display') ||
        pathname[1] === 'career' ? "" : <NavbarHomeScreen id="main" />}
      {/* {posthog.has_opted_out_capturing() // new
        || posthog.has_opted_in_capturing()
        ? null
        : cookie ? <CookieBanner /> : null
      } */}
      <Routes>
        <Route path='/' exact element={<Login />} />
        <Route path='/registerAndUpdate' exact element={<RegistrationSectionOne />} />
        <Route path='/userprofile' exact element={<TabMenu />} />
        <Route path='/index' exact element={<IndexPage />} />
        <Route path='/applyleave' exact element={<ApplyLeave />} />
        <Route path='/applytimesheet' exact element={<ApplyTimeSheet />} />
        <Route path='/holidaylist' exact element={<HolidayList />} />
        <Route path='/editemployee' exact element={<HrmsEditEmployee />} />
        <Route path='/addcustomers' exact element={<AddCustomers />} />
        <Route path='/addproject' exact element={<AddProject />} />
        <Route path='/addtask' exact element={<AddTask />} />
        <Route path='/addreport' exact element={<AddReporting />} />
        <Route path='/upload-holiday-list' exact element={<UploadHolidayList />} />
        <Route path='/changepassword' exact element={<ChangePassword />} />
        <Route path='/employeerelieving' exact element={<EmployeeRelievingStatus />} />
        <Route path='/Hrpolicy' exact element={<HRPolicy />} />
        <Route path='/leavepolicy' exact element={<LeaveHR />} />
        <Route path='/employeeRecords' exact element={<EmployeeDatas />} />
        <Route path='/customerRecords' exact element={<CustomerDatas />} />
        <Route path='/projectRecords' exact element={<ProjectDatas />} />
        <Route path='/taskRecords' exact element={<TaskDatas />} />
        <Route path='/reportRecords' exact element={<ReportDatas />} />
        <Route path='/over-All-Employee-Records' exact element={<OverAllEmployeeTable />} />
        <Route path='/leaveRecords' exact element={<LeaveTable />} />
        <Route path='/over-All-Leave-Records' exact element={<OverAllLeaveTable />} />
        <Route path='/Leave-Approvals' exact element={<LeaveApprovalTable />} />
        {/* <Route path='/mainMenu' exact element={<MainMenu />} /> */}
        <Route path='/forgotPassword' exact element={<ForgotPassword />} />
        <Route path='/viewAppliedTimeSheet' exact element={<TimeSheetTable />} />
        <Route path='/timeSheetApproval' exact element={<TimeSheetApproval />} />
        <Route path='/overalltimeSheetApproval' exact element={<OverAllTimeSheet />} />
        <Route path='/homePage' exact element={<HomePage />} />
        <Route path='/myProfilePage' exact element={<MyProfilePage />} />
        <Route path='/profile' exact element={<ProfilePage />} />
        <Route path='/leavebalance' exact element={<Leavebalance />} />
        <Route path='/reportgeneration' exact element={<ReportGeneration />} />
        {/* ///auth */}
        <Route path='/customer-relation-management' exact element={<CRM_Main_Page />} />
        <Route path='/resource-allocation' exact element={<Allocateresources />} />
        <Route path='/resource-allocation/viewAllocatedResources' exact element={<AllocationTable />} />
        {/* auth */}
        <Route path='/upload-payslip' exact element={<PrivateIndexRoute>
          <PaySlipUploading />
        </PrivateIndexRoute>} />
        <Route path='/upload-appraisal' exact element={<PrivateIndexRoute>
          <UploadAppraisal />
        </PrivateIndexRoute>} />
        <Route path='/upload-vacancies' exact element={<PrivateIndexRoute>
          <Jobuploads />
        </PrivateIndexRoute>} />
        <Route path='/upload-vacancies/viewJobs' exact element={<PrivateIndexRoute>
          <Viewjobs />
        </PrivateIndexRoute>} />
        <Route path='/career/:jobNo' exact element={<Jobdisplaypage />} />
        <Route path='/job-Applications' exact
          element={<PrivateIndexRoute>
            <JobApplicationTable />
          </PrivateIndexRoute>} />
        <Route path='/employee-attendance' exact
          element={<PrivateIndexRoute>
            <AttendanceTable />
          </PrivateIndexRoute>} />
        <Route path={`/customer-relation-management/:id/:project/:customer`} exact
          element={<PrivateIndexRoute>
            <TicketsPage />
          </PrivateIndexRoute>} />
        <Route path={`/ticket-display/:ticketID`} exact
          element={<PrivateIndexRoute>
            <TicketDisplay />
          </PrivateIndexRoute>} />
        <Route path={`/upload-Form16`} exact
          element={<PrivateIndexRoute>
            <Form16 />
          </PrivateIndexRoute>} />
        <Route path={`/tickets`} exact
          element={<PrivateIndexRoute>
            <AllTickets isPersonal={true} />
          </PrivateIndexRoute>} />
        <Route path={`/replaceReportingManager`} exact
          element={<PrivateIndexRoute>
            <ReportingManager />
          </PrivateIndexRoute>} />
        <Route path='*' exact element={<NotFound />} />
      </Routes>
      <HrmsFooter />
    </Suspense>

  );
}

export default App;
