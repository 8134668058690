import React, { useEffect } from 'react'
import axios from 'axios';
export default async function Auth() {
   //  console.log("Auth token " + localStorage.getItem('token'))
    const result = await axios.get(`${process.env.REACT_APP_DOMAINNAME}/isAuthenticate`, {
       headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
       }
    })
   //  console.log(result.data.message)
    return result.data.message ? true: false
 }
